import * as React from "react"
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from "../components/global/layout"
import { Masthead } from "../components/layout/masthead"

const NotFoundPage = () => (
    <Layout pageTitle="Page Not Found"
            metaTitle="Page Not Found"
            metaDescription="Unfortunately the page you tried to access was unavailable.">
        <Masthead title="Page Not Found" summary="Unfortunately the page you tried to access was unavailable." />
        <div className="c-content">
            <div className="container">
                <div className="row">
                    <div className="c-content__container cms-content">
                        <p>This might be because:</p>
                        <ul>
                            <li>You may have typed the web address incorrectly. Please check the address and spelling ensuring that it does not contain capital letters or spaces.</li>
                            <li>It is possible that the page you were looking for may have been moved, updated or deleted.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
